<script>
import deviceService from '@/service/devices.service';
import { loadPlayer } from 'rtsp-relay/browser';

export default {
  data() {
    return {
      devices: [],
      currentDevice: {},
      player: null,
      canvas: null,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      const res = await deviceService.find();
      this.devices = res.data;
      setTimeout(() => {
        this.devices.forEach(async (device, index) => {
          if (index === 0) {
            this.currentDevice = device;
            await this.loadMainPlayer(device.id);
          }
          let canvas = this.$refs[`canvas_list_${index}`];
          if (canvas && canvas[0]) canvas = canvas[0];
          await this.loadPlayer(device.id, canvas);
        });
      }, 200);
    },

    async loadPlayer(deviceId, canvas) {
      const url = await deviceService.getStreamLink(deviceId);
      loadPlayer({
        url,
        canvas,
        onDisconnect: () => console.log('Connection lost!'),
      });
    },

    async loadMainPlayer(deviceId) {
      this.canvas = document.createElement('canvas');
      this.canvas.width = 800;
      this.canvas.height = 480;
      this.$refs.canvas_container.appendChild(this.canvas);

      const url = await deviceService.getStreamLink(deviceId);

      await loadPlayer({
        url,
        canvas: this.canvas,
        onDisconnect: () => console.log('Connection lost!'),
      });
    },

    handleChangeCurrentDevice(device) {
      this.$refs.canvas_container.removeChild(this.canvas);
      this.canvas = null;
      this.currentDevice = device;
      this.loadMainPlayer(device.id);
    },
  },
};
</script>

<template>
  <div class="mt-10">
    <v-row>
      <v-col md="8">
        <h5 class="pb-1">Câmeras ao vivo</h5>
        <v-card>
          <v-card-text>
            <h5>{{ currentDevice.name }}</h5>
            <div ref="canvas_container" class="live-cam"></div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4">
        <h5 class="pb-1">Todas as câmeras</h5>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col
                v-for="(device, index) in devices"
                :key="device.name + index"
                md="6"
                style="cursor: pointer"
                @click.prevent="handleChangeCurrentDevice(device)"
              >
                <h5>{{ device.name }}</h5>
                <div class="live-cam">
                  <canvas :ref="`canvas_list_${index}`" class="canvas-list" width="200" height="180"></canvas>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.live-cam {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;

  .canvas-current {
    max-height: 400px;
    display: block;
  }
  .canvas-list {
    max-height: 177px;
    display: block;
  }
}
</style>
